<template>
  <div>
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :cancel_loading="cancel_loading"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="update"
      :save_loading="save_loading"
      :btnSaveNew="false"
      :btnDuplicate="true"
      :duplicate_loading="duplicate_loading"
      :textDuplicate="$t('GENERAL.DUPLICATE')"
      v-on:duplicate="duplicate"
      :btnDelete="true"
      :delete_loading="delete_loading"
      :textDelete="$t('GENERAL.DELETE')"
      v-on:delete="deleteRecord()"
    />
    <v-card elevation="2">
      <v-card-title>
        {{ $t("TIMETABLE.EDIT_TIMETABLE") }}
      </v-card-title>
      <v-card-text>
        <TimetableTemplate
          :key="timetable.productTimetableTemplateId"
          :timetableTemplate="timetable"
          :showName="true"
          showCourses="true"
          v-on:save_timetable_template="save_timetable_template($event)"
          ref="timetable"
        ></TimetableTemplate>
      </v-card-text>
    </v-card>

    <v-dialog v-model="isShowDialogDelete" max-width="350" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="
              isShowDialogDelete = false;
              delete_loading = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="removeRecord()">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ForceDelete
      typeProduct="TIMETABLE"
      :redirect="false"
      :validateCourses="validateCourses"
      @close-force-delete="cleanDataForceDelete()"
      :isShow="isShowDialogForceDelete"
      :showForceDelete="false"
    ></ForceDelete>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TimetableTemplate from "@/view/content/components/forms/TimetableTemplate";
import { UPDATE_PRODUCT_TIMETABLE_TEMPLATE } from "@/core/services/store/general/time_table.module";
import { DELETE_PRODUCT_TIMETABLE_TEMPLATE } from "@/core/services/store/general/time_table.module";
import { STORE_PRODUCT_TIMETABLE_TEMPLATE } from "@/core/services/store/general/time_table.module";
import Buttons from "@/view/content/components/Buttons";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";

export default {
  data() {
    return {
      dialog: false,
      isShowDialogDelete: false,
      isEditCourse: true,
      timetableTemplate_modified: {},
      cancel_loading: false,
      save_loading: false,
      duplicate_loading: false,
      delete_loading: false,
      isShowDialogForceDelete: false,
      validateCourses: [],
    };
  },
  name: "timetables",
  components: {
    TimetableTemplate,
    Buttons,
    ForceDelete,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Timetables" }]);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Timetables", route: "/configure/timetables/" },
      { title: this.$t("TIMETABLE.EDIT_TIMETABLE") },
    ]);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Timetables", route: "/configure/timetables/" },
      { title: this.$t("TIMETABLE.EDIT_TIMETABLE") },
    ]);
    this.$refs.timetable.mountDataProp();
  },
  computed: {
    ...mapGetters(["campus", "timeTables"]),
    timetable() {
      var timetables = this.timeTables.filter(timetable => {
        if (
          parseInt(timetable.productTimetableTemplateId) ===
          parseInt(this.$route.params.id)
        )
          return true;
        return false;
      });
      return timetables[0];
    },
  },
  methods: {
    cancel() {
      this.$router.push("/configure/timetables");
    },
    deleteRecord() {
      this.delete_loading = true;
      this.isShowDialogDelete = true;
    },
    async removeRecord() {
      await this.$store
        .dispatch(
          DELETE_PRODUCT_TIMETABLE_TEMPLATE,
          this.timetableTemplate_modified.productTimetableTemplateId
        )
        .then(result => {
          if (result.data.success) {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            this.dialog = false;
            this.isShowDialogDelete = false;
            this.$router.push("/configure/timetables");
            this.delete_loading = false;
          } else {
            this.dialog = false;
            this.isShowDialogDelete = false;
            if (
              result.data.productTimetableTemplate &&
              result.data.productTimetableTemplate.product_course.length > 0
            ) {
              this.validateCourses =
                result.data.productTimetableTemplate.product_course;
              this.isShowDialogForceDelete = true;
            }
          }
        })
        .catch(error => {
          if (typeof error.response !== "undefined") {
            if (typeof error.response.data.errors !== "undefined") {
              for (let field of Object.keys(error.response.data.errors)) {
                this.$bvToast.toast(error.response.data.errors[field][0], {
                  title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                  variant: "danger",
                  solid: true,
                });
              }
            } else {
              if (typeof error.response.data !== "undefined") {
                this.$bvToast.toast(error.response.data.message, {
                  title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                  variant: "danger",
                  solid: true,
                });
              }
            }
          }
          this.isShowDialogDelete = false;
        });
    },
    save_timetable_template(timetableTemplate_modified) {
      this.timetableTemplate_modified = timetableTemplate_modified;
    },
    async update() {
      this.$refs.timetable.$refs.observer.validate().then(async data => {
        if (data) {
          this.save_loading = true;

          await this.$store
            .dispatch(
              UPDATE_PRODUCT_TIMETABLE_TEMPLATE,
              Vue.util.extend({}, this.timetableTemplate_modified)
            )
            .then(() => {
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
              this.dialog = false;
              this.save_loading = false;
            })
            .catch(error => {
              if (typeof error.response !== "undefined") {
                if (typeof error.response.data.errors !== "undefined") {
                  for (let field of Object.keys(error.response.data.errors)) {
                    this.$bvToast.toast(error.response.data.errors[field][0], {
                      title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                      variant: "danger",
                      solid: true,
                    });
                  }
                } else {
                  if (typeof error.response.data !== "undefined") {
                    this.$bvToast.toast(error.response.data.message, {
                      title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                      variant: "danger",
                      solid: true,
                    });
                  }
                }
              }
            });
        } else {
          this.duplicate_loading = false;
          this.$bvToast.toast(this.$t("GENERAL.VALIDATE_FIELDS"), {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      });
    },

    async duplicate() {
      this.duplicate_loading = true;
      this.$refs.timetable.$refs.observer.validate().then(async data => {
        if (data) {
          await this.$store
            .dispatch(
              STORE_PRODUCT_TIMETABLE_TEMPLATE,
              Vue.util.extend(
                {},
                {
                  ...this.timetableTemplate_modified,
                  name:
                    this.timetableTemplate_modified.name +
                    " " +
                    this.$t("GENERAL.COPY_DUPLICATE"),
                }
              )
            )
            .then(result => {
              result;
              this.dialog = false;
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
              this.duplicate_loading = false;
              this.$router.push(
                `/configure/timetables/edit/${result.data.productTimetableTemplate.productTimetableTemplateId}`
              );
            });
        } else {
          this.duplicate_loading = false;
          this.$bvToast.toast(this.$t("GENERAL.VALIDATE_FIELDS"), {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      });
    },
    cleanDataForceDelete() {
      this.validateCourses = [];
      this.delete_loading = false;
    },
  },
};
</script>
